import React, { type FunctionComponent } from 'react';
import { formatPrice } from '../../../helpers/general-helper/general-helper';

export type SearchProductCardPriceProps = {
	price: number;
	units?: string;
	startingAt?: boolean;
	className?: string;
};

export const SearchProductCardPrice: FunctionComponent<SearchProductCardPriceProps> = ({
	price,
	units,
	startingAt = false,
	className = 'b'
}) => {
	if (!price) {
		return null;
	}
	return (
		<span className={className}>
			{startingAt && 'Starting at '}
			{formatPrice(price)}
			{units && <> / {units}</>}
		</span>
	);
};
