import React, { type FunctionComponent, type HTMLAttributes, useContext } from 'react';
import { type SearchVariantFieldsFragment } from '../../../__generated__/graphql-client-types';
import { SearchVariantContext } from '../../../contexts/search/search-variant.context';
import { TrackedEvent } from '../../../helpers/analytics/event-types';
import { handleKeys } from '../../../helpers/keyboard/keyboard.helper';
import { useTrackEvent } from '../../../hooks/analytics/analytics.hooks';

export const DEFAULT_VARIANT_SIZE = 27;

type SwatchProps = {
	variant: SearchVariantFieldsFragment;
	variantSize?: number;
	testId?: string;
};

const Swatch: FunctionComponent<SwatchProps> = ({ variant, variantSize = DEFAULT_VARIANT_SIZE, testId }) => {
	return (
		<div
			title={variant.name}
			data-testid={testId}
			style={{
				backgroundColor: `#${variant.swatchColor}`,
				width: `${variantSize}px`,
				height: `${variantSize}px`,
				margin: '2px'
			}}
		/>
	);
};

export type SearchVariantProps = {
	variant: SearchVariantFieldsFragment;
	variantSize?: number;
	shouldTrackSelection?: boolean;
} & Pick<HTMLAttributes<HTMLElement>, 'className'>;

export const SearchVariant: FunctionComponent<SearchVariantProps> = ({
	variant,
	className = '',
	variantSize = DEFAULT_VARIANT_SIZE,
	shouldTrackSelection
}) => {
	const { setHoveredVariant, setSelectedVariant, hoveredVariant, selectedVariant } = useContext(SearchVariantContext);
	const trackEvent = useTrackEvent();

	const isActive = (hoveredVariant ? hoveredVariant?.id : selectedVariant.id) === variant.id;

	const handleSelect = () => {
		setSelectedVariant(variant);
		if (shouldTrackSelection) {
			trackEvent({ event: TrackedEvent.PLP_SELECTED_FINISH });
		}
	};

	const handleMouseEnter = () => {
		setHoveredVariant(variant);
	};

	const handleMouseLeave = () => {
		setHoveredVariant();
	};

	return (
		<div
			aria-checked={isActive}
			tabIndex={0}
			className="flex bw2 br b--theme-white"
			onClick={handleSelect}
			onKeyDown={handleKeys(['Enter', ' '], handleSelect)}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			data-testid={`variantContent-${variant.id}`}
			data-automation="search-finish-swatch"
			role="checkbox">
			<div className={`ba br1 pointer outline-0 bw1 ${isActive ? 'b--theme-primary' : 'b--theme-grey-lighter'} ${className}`}>
				<Swatch variant={variant} variantSize={variantSize} testId={`${variant.name.toLowerCase()}-swatch-${variantSize}`} />
			</div>
		</div>
	);
};
