import { type SearchVariantFieldsFragment } from '../../__generated__/graphql-client-types';

export const getVariantsToDisplay = (
	variants: SearchVariantFieldsFragment[],
	maxNumberToDisplay: number,
	groupNum: number
): SearchVariantFieldsFragment[] => {
	const startNumber = getStartNumber(groupNum, maxNumberToDisplay, variants.length);
	const endNumber = getEndNumber(groupNum, maxNumberToDisplay, variants.length);

	return variants.slice(startNumber, endNumber);
};

export const getStartNumber = (groupNum: number, maxNumberToDisplay: number, numberOfElements: number) => {
	if (groupNum <= 1) {
		return 0;
	}

	let increase = 0;
	if (groupNum >= 2) {
		increase = 1;
	}

	const numberToDisplay = getNumberToDisplay(groupNum, maxNumberToDisplay, numberOfElements);
	if (numberToDisplay === 0) {
		return 0;
	}

	return numberToDisplay + (groupNum - 2) * numberToDisplay + increase;
};

export const getEndNumber = (groupNum: number, maxNumberToDisplay: number, numberOfElements: number) => {
	const numberToDisplay = getNumberToDisplay(groupNum, maxNumberToDisplay, numberOfElements);

	if (numberToDisplay === 0) {
		return 0;
	}

	if (groupNum >= getTotalGroups(numberOfElements, maxNumberToDisplay)) {
		return numberOfElements;
	}

	return getStartNumber(groupNum, maxNumberToDisplay, numberOfElements) + numberToDisplay;
};

export const getTotalGroups = (numberOfElements: number, numberToDisplay: number): number => {
	let groupCount = 0;
	const adjustedToDisplay = numberToDisplay - 2;

	if (numberToDisplay === 1) {
		return numberOfElements;
	}

	if (numberOfElements <= numberToDisplay) {
		return 1;
	}

	let iterator = 1;
	while (numberOfElements > iterator) {
		if (iterator === numberToDisplay + groupCount * adjustedToDisplay) {
			groupCount++;
		}
		iterator++;
	}

	return groupCount + 1;
};

export const getNumberToDisplay = (groupNum: number, maxNumberToDisplay: number, numberOfElements: number) => {
	if (getTotalGroups(numberOfElements, maxNumberToDisplay) < groupNum) {
		return 0;
	}

	return groupNum > 1 ? maxNumberToDisplay - 2 : maxNumberToDisplay - 1;
};
